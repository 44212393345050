import React, { useState, useEffect } from "react";
import { db } from "../src/database/firebase-config";
import { collection, getDocs } from "firebase/firestore";
import "./App.css";
import Home from "./pages/Home";
import About from "./pages/About";
import Servicii from "./pages/Servicii";
import Proiecte from "./pages/Proiecte";
import Contact from "./pages/Contact";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Whatsapp from "../src/assets/whatsapp.png";

function App() {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(db, "proiecte"));
      const projectsArray = [];
      querySnapshot.forEach((doc) => {
        projectsArray.push({ id: doc.id, ...doc.data() });
      });
      setProjects(projectsArray);
    };

    fetchData();
  }, []);

  return (
    <div className="App">
      <Navbar />
      <a
        className="button-whatsapp"
        href="https://api.whatsapp.com/send?phone=40766323325">
        <img src={Whatsapp} alt="buton chat whatsapp" />
      </a>
      <div className="section" id="home">
        <Home />
      </div>
      <div className="section" id="proiecte">
        <Proiecte projects={projects} />
      </div>
      <div className="section" id="about">
        <About />
      </div>
      <div className="section" id="servicii">
        <Servicii />
      </div>
      <div className="section" id="contact">
        <Contact />
      </div>
      <Footer />
    </div>
  );
}

export default App;
