import React from "react";
import "../styling/contact.css";
import Mail from "../assets/mail.png";
import Phone from "../assets/whatsapp1.png";
import Facebook from "../assets/facebook.png";
import TikTok from "../assets/tik-tok.png";

const Contact = () => {
  return (
    <div className="contact-container">
      <div className="left-side-contact"></div>
      <div className="right-side-contact">
        <div className="contacteaza-ne">Contacteaza-ne!</div>
        <div className="infos-contact">
          <div className="mail-div">
            <a href="mailto:office@proiect3d.ro">
              <img src={Mail} alt="social network" />
              office@proiect3d.ro{" "}
            </a>
          </div>
          <div className="phone-div">
            {" "}
            <a href="https://api.whatsapp.com/send?phone=40766323325">
              <img src={Phone} alt="social network" /> +40 766323325
            </a>
          </div>
          <div className="icon-social-div">
            <a
              target="blank"
              href="https://www.facebook.com/BruleaLoredanaMihaela">
              <img src={Facebook} alt="social network" /> Facebook
            </a>
          </div>
          <div className="icon-social-div">
            <a
              target="blank"
              href="https://www.tiktok.com/@evaloredana767?_t=8kq6b6EyMsr&_r=1">
              <img src={TikTok} alt="social network" />
              TikTok
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
