import React from "react";
import "../styling/home.css";
import CookieConsent from "react-cookie-consent";

function Home() {
 
  return (
    <div className="home-container">
      <header className="header-homepage">
        <h1 className="header-h1">
          Explorând dimensiunile: Proiecte 3D care iau viață!
        </h1>
        <h2 className="header-h2">
          Descoperă universul fascinant al Proiectelor 3D: <br /> De la idee la
          realitate digitală.
        </h2>
      </header>
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#000000", position: "absolute", bottom: "3rem" }}
        buttonStyle={{ color: "#000000", fontSize: "20px" }}
        expires={150}>
        Prin continuarea navigării, ești de acord cu utilizarea cookie-urilor și
        prelucrarea datelor personale conform Politicii noastre de
        Confidențialitate.
      </CookieConsent>
    </div>
  );
}

export default Home;
