import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";
import "../styling/navbar.css";
import Logo from "../assets/logo.png";

const Navbar = () => {
  const [isChecked, setIsChecked] = useState(false);



  useEffect(() => {
    // Get the navbar element by class
    const navbar = document.querySelector(".header-navbar");
    if (isChecked) {
      // Add the 'navbar-black' class if menu is open
      navbar.classList.add("navbar-black");
    } else {
      // Remove the 'navbar-black' class if menu is closed
      navbar.classList.remove("navbar-black");
    }
  }, [isChecked]); // This useEffect runs whenever isChecked changes

  const handleButtonClick = () => {
    setIsChecked(!isChecked);
  };
  return (
    <header className="header-navbar">
      <Link
        className="logo-navbar-link"
        to="home"
        spy={true}
        smooth={true}
        duration={500}>
        <img
          className="logo-navbar"
          src={Logo}
          alt="proiecte3d brand visual identity"
        />
      </Link>
      <input
        className="menu-btn"
        type="checkbox"
        id="menu-btn"
        checked={isChecked}
        onChange={handleButtonClick}
      />
      <label className="menu-icon" htmlFor="menu-btn">
        <span className="navicon"></span>
      </label>
      <ul className="menu">
        {" "}
        <li className="header-li-hover">
          <Link
            className="button-nav-a"
            to="proiecte"
            spy={true}
            smooth={true}
            offset={-30}
            duration={500}
            onClick={handleButtonClick}>
            Proiecte
          </Link>
        </li>
        <li className="header-li-hover">
          <Link
            className="button-nav-a"
            to="about"
            spy={true}
            smooth={true}
            duration={500}
            onClick={handleButtonClick}>
            Despre
          </Link>
        </li>{" "}
        <li className="header-li-hover">
          <Link
            className="button-nav-a"
            to="servicii"
            spy={true}
            smooth={true}
            duration={500}
            onClick={handleButtonClick}>
            Servicii
          </Link>
        </li>
        <li className="header-li-hover">
          <Link
            className="button-nav-a"
            to="contact"
            spy={true}
            smooth={true}
            duration={500}
            onClick={handleButtonClick}>
            Contact
          </Link>
        </li>
        <li>
          <a
            href="mailto:office@proiect3d.ro"
            className="button-1 button-contact"
            onClick={handleButtonClick}>
            <span className="spn2">CERE OFERTA</span>
          </a>
        </li>
      </ul>
    </header>
  );
};

export default Navbar;
