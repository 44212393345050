import React from "react";
import "../styling/card.css";

const Card = ({
  imageUrl,
  name,
  dormitoare,
  bai,
  suprafataConstruita,
  onClick,
}) => {
  return (
    <div className="card-container">
      {" "}
      <img className="image-card" src={imageUrl} alt={name} />
      <div className="card-info">
        <div className="name-and-button">
          <h2>{name}</h2>
          <button className="button-card" onClick={onClick}>
            <span>DETALII</span>
          </button>
        </div>
        <div className="info-details">
          <div>Dormitoare: {dormitoare}</div>
          <div>Bai: {bai}</div>
          <div>Sup. Construită: {suprafataConstruita}mp</div>
        </div>
      </div>
    </div>
  );
};

export default Card;
