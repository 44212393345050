import React from "react";
import "../styling/footer.css";
import LogoFooter from "../assets/logofooter.png";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="upper-footer">
        <div className="slot slot-1">
          <img
            className="logo-footer"
            src={LogoFooter}
            alt="brand identity footer"
          />
          <p>
            Concepem si elaborăm proiecte de case și vile cu un design
            arhitectural deosebit, oferind clienților servicii complete și
            profesionale de inginerie.
          </p>
        </div>
        <div className="slot slot-2">
          <h5>Contact Us</h5>
          <p className="slot-2-p">
            <a
              className="link-slot2"
              href="https://www.google.com/maps/place/Bulevardul+Alexandru+Ioan+Cuza,+Slatina/@44.4265818,24.3735418,17z/data=!3m1!4b1!4m6!3m5!1s0x40ad383591cb4f23:0xd2df59fc30ac39c8!8m2!3d44.426578!4d24.3761167!16s%2Fg%2F1tgfnmqf?entry=ttu"
              target="blank">
              Bulevardul A.I. Cuza, Slatina, Romania
            </a>
          </p>
          <p className="slot-2-p">
            <a className="link-slot2" href="tel:+40766323325">
              +40 766323325
            </a>
          </p>
          <p className="slot-2-p">
            <a className="link-slot2" href="mailto:office@proiect3d.ro">
              office@proiect3d.ro
            </a>
          </p>
        </div>
        <div className="slot slot-3">
          <div className="social-icons">
            <h5>Follow Us</h5>
            <a
              className="social-connect"
              target="blank"
              href="https://www.facebook.com/BruleaLoredanaMihaela">
              Facebook
            </a>
            {/* <a
              className="social-connect"
              target="blank"
              href="https://www.facebook.com/BruleaLoredanaMihaela">
              <img src={Instagram} alt="social media icon" />
            </a> */}
            <a
              className="social-connect"
              target="blank"
              href="https://www.tiktok.com/@evaloredana767?_t=8kq6b6EyMsr&_r=1">
              TikTok
            </a>
          </div>
          <p className="copyrights">© 2024 Proiect3D | All Rights Reserved </p>
        </div>
      </div>
      <div className="lower-footer">
        created by{" "}
        <a href="http://upvisionmedia.com" target="blank">
          upvisionMedia
        </a>
      </div>
    </div>
  );
};

export default Footer;
