import React, { useCallback, useEffect, useRef, useState } from "react";

import "../styling/proiecte.css";
import Card from "../components/Card";
import ProjectDetailPage from "../components/ProjectDetailPage";

const Proiecte = ({ projects }) => {
  const containerRef = useRef(null);
  const [showAllProjects, setShowAllProjects] = useState(false);
  const [filteredProjects, setFilteredProjects] = useState([]);

  const [selectedProject, setSelectedProject] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [bedroomFilter, setBedroomFilter] = useState("Toate");
  const [bathroomFilter, setBathroomFilter] = useState("Toate");
  const [surfaceRange, setSurfaceRange] = useState([0, 500]);

  useEffect(() => {
    setFilteredProjects(projects); // Change projectsProp to projects
  }, [projects]); // Change projectsProp to projects

  const filterProjects = useCallback(() => {
    if (!projects) return;
    let filtered = projects.filter((project) => {
      const dormitoareMatch =
        bedroomFilter === "Toate" ||
        parseInt(project.dormitoare) >= parseInt(bedroomFilter, 10);
      const baiMatch =
        bathroomFilter === "Toate" ||
        parseInt(project.bai) >= parseInt(bathroomFilter, 10);
      const surfaceMatch =
        parseInt(project.suprafataConstruita) >=
          surfaceRange[0] &&
        parseInt(project.suprafataConstruita) <=
          surfaceRange[1];
      return dormitoareMatch && baiMatch && surfaceMatch;
    });
    setFilteredProjects(filtered);
  }, [projects, bedroomFilter, bathroomFilter, surfaceRange]);

  useEffect(() => {
    filterProjects();
  }, [filterProjects]);

  const handleProjectDetails = (projectId) => {
    setSelectedProject(projectId);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedProject(null);
  };

  const handleToggleProjects = () => {
    setShowAllProjects(!showAllProjects);
  };
  return (
    <div className="container-proiecte-all" ref={containerRef}>
      <div className="buttons-filter">
        <div>
          <span>Filtreaza dormitoare:</span>
          <select onChange={(e) => setBedroomFilter(e.target.value)}>
            <option value="Toate">Toate</option>
            <option value="1">1 dormitor</option>
            <option value="2">2 dormitoare</option>
            <option value="3">3 dormitoare</option>
            <option value="4">4 dormitoare</option>
          </select>
        </div>
        <div>
          <span>Filtreaza bai:</span>
          <select onChange={(e) => setBathroomFilter(e.target.value)}>
            <option value="Toate">Toate</option>
            <option value="1">1 baie</option>
            <option value="2">2 bai</option>
            <option value="3">3 bai</option>
            <option value="4">4 bai</option>
          </select>
        </div>{" "}
        <div className="range-div">
          {" "}
          {/* Range slider for suprafataConstruita */}
          <label className="range" htmlFor="surfaceRange">
            Suprafața construită (mp): {surfaceRange[0]} - {surfaceRange[1]}
          </label>
          <input
            id="surfaceRange"
            type="range"
            min="0"
            max="500" // Adjust based on your data
            value={surfaceRange[1]} // Using the second value for the slider; for dual-thumb, you'd manage two values.
            onChange={(e) =>
              setSurfaceRange([surfaceRange[0], parseInt(e.target.value)])
            }
            step="10"
          />
        </div>
      </div>
      <div className="proiecte-container">
        {showAllProjects
          ? filteredProjects
              .slice(0, 4)
              .map((project) => (
                <Card
                  key={project.id}
                  imageUrl={project.imageUrl}
                  name={project.name}
                  info={project.info}
                  dormitoare={project.dormitoare}
                  bai={project.bai}
                  suprafataConstruita={project.suprafataConstruita}
                  onClick={() => handleProjectDetails(project.id)}
                />
              ))
          : filteredProjects.map((project) => (
              <Card
                key={project.id}
                imageUrl={project.imageUrl}
                name={project.name}
                info={project.info}
                dormitoare={project.dormitoare}
                bai={project.bai}
                suprafataConstruita={project.suprafataConstruita}
                onClick={() => handleProjectDetails(project.id)}
              />
            ))}
      </div>

      <div className="frame-12143" onClick={handleToggleProjects}>
        <svg
          className="akar-icons-circle-chevron-down-fill"
          width="48"
          height="49"
          viewBox="0 0 48 49"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24 2.47803C11.85 2.47803 2 12.328 2 24.478C2 36.628 11.85 46.478 24 46.478C36.15 46.478 46 36.628 46 24.478C46 12.328 36.15 2.47803 24 2.47803ZM17.414 20.064C17.0368 19.6997 16.5316 19.4981 16.0072 19.5027C15.4828 19.5072 14.9812 19.7176 14.6104 20.0884C14.2395 20.4592 14.0292 20.9608 14.0247 21.4852C14.0201 22.0096 14.2217 22.5148 14.586 22.892L22.586 30.892C22.9611 31.267 23.4697 31.4776 24 31.4776C24.5303 31.4776 25.0389 31.267 25.414 30.892L33.414 22.892C33.7783 22.5148 33.9799 22.0096 33.9753 21.4852C33.9708 20.9608 33.7605 20.4592 33.3896 20.0884C33.0188 19.7176 32.5172 19.5072 31.9928 19.5027C31.4684 19.4981 30.9632 19.6997 30.586 20.064L24 26.65L17.414 20.064Z"
            fill="white"
          />
        </svg>
        <div className="toate-proiectele">Toate proiectele</div>
      </div>
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <ProjectDetailPage
              project={filteredProjects.find((p) => p.id === selectedProject)}
              closeModal={handleCloseModal}
              imageCarousel={
                filteredProjects.find((p) => p.id === selectedProject)
                  ?.imageCarousel
              }
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Proiecte;
