import React from "react";
import "../styling/about.css";

const About = () => {
  return (
    <div className="about-container">
      <div className="left-side">
        <div className="despre-noi">Despre noi</div>
        <div className="despre-noi-text" lang="ro">
          Echipa noastră de arhitecți și ingineri lucrează îndeaproape cu
          clienții pentru a transforma viziunile lor în realitate. De la concept
          la finalizare, ne angajăm să oferim soluții inovatoare și de calitate,
          adaptate nevoilor și preferințelor individuale ale fiecărui client.
          Fie că este vorba despre proiectarea unei case moderne sau a unei vile
          tradiționale, punem accentul pe detalii și funcționalitate pentru a
          crea spații care să îmbine frumusețea estetică cu eficiența
          structurală.
        </div>
        <div className="contact">
          <a className="contact-1" href="mailto:office@proiect3d.ro">
            <span> Contacteaza-ne </span>
          </a>
        </div>
      </div>
      <div className="right-side">
        <div className="frame">
          <div className="frame-1">100+</div>
          <div className="frame-2">Proiecte</div>
          <div className="frame-3">Realizate cu success.</div>
        </div>
        <div className="frame">
          <div className="frame-1">10+</div>
          <div className="frame-2">Locatii</div>
          <div className="frame-3">diferite in toata Romania.</div>
        </div>
        <div className="frame">
          <div className="frame-1">24/7</div>
          <div className="frame-2">Disponibili</div>
          <div className="frame-3">pentru a va raspunde la intrebari.</div>
        </div>
      </div>
    </div>
  );
};

export default About;
