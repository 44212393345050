import React, { useEffect, useState } from "react";
import "../styling/projectDetail.css";
import CloseButton from "../assets/close.png";
import Rooms from "../assets/room.png";
import Bath from "../assets/bath.png";
import Measure from "../assets/measure.png";

const ProjectDetailPage = ({ project, closeModal, imageCarousel }) => {
  const { name } = project;

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      // Increment current image index in a cyclic manner
      setCurrentImageIndex((prevIndex) =>
        prevIndex === imageCarousel.length - 1 ? 0 : prevIndex + 1
      );
    }, 10000); // Change image every 10 seconds

    return () => clearInterval(interval);
  }, [imageCarousel.length]);

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === imageCarousel.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? imageCarousel.length - 1 : prevIndex - 1
    );
  };

  const handleCloseModal = () => {
    closeModal();
  };

  return (
    <div className="project-detail-container">
      <button className="close-button" onClick={handleCloseModal}>
        <img src={CloseButton} alt="a X button for close the window" />
      </button>
      <h1 className="title-project-detail">{name}</h1>
      <div className="slideshow-container">
        {imageCarousel?.map((image, index) => (
          <img
            className={`image-details ${
              index === currentImageIndex ? "active" : ""
            }`}
            key={index}
            src={image}
            alt={name}
          />
        ))}
        <div className="image-overlay"></div>
        <div onClick={handlePrevImage} className="arrow-1">
          <div className="arrow-top"></div>
          <div className="arrow-bottom"></div>
        </div>
        <div onClick={handleNextImage} className="arrow-2">
          <div className="arrow-top"></div>
          <div className="arrow-bottom"></div>
        </div>
      </div>

      <div className="important-info">
        <div className="rooms">
          <img className="icon-info" src={Measure} alt="icon measure" />
          <h4>Sup. Construita: {project.suprafataConstruita}</h4>
        </div>
        <div className="bath">
          <img className="icon-info" src={Bath} alt="icon bath" />
          <h4>Bai: {project.bai}</h4>
        </div>
        <div className="surface">
          <img className="icon-info" src={Rooms} alt="icon rooms" />
          <h4>Dormitoare: {project.dormitoare}</h4>
        </div>
      </div>
      <div className="other-info">
        <h5>
          <span className="span1-projectDetail">Sup. Desfasurata:</span>{" "}
          
            {project.suprafataDesfasurata}mp
        </h5>
        <h5>
          <span className="span1-projectDetail">Balcoane:</span>{" "}
          {project.balcoane}
        </h5>
        <h5>
          <span className="span1-projectDetail">Terase:</span> {project.terase}
        </h5>
        <h5>
          <span className="span1-projectDetail">Stil:</span> {project.stil}
        </h5>
        <h5>
          <span className="span1-projectDetail">Garaj:</span> {project.garaj}
        </h5>
      </div>
    </div>
  );
};

export default ProjectDetailPage;
