import React from "react";
import "../styling/servicii.css";
import Icon1 from "../assets/icon.png";
import Icon2 from "../assets/icon2.png";
import Icon3 from "../assets/icon3.png";
import Icon4 from "../assets/icon4.png";
import Icon5 from "../assets/icon5.png";
import Icon6 from "../assets/icon6.png";

const Servicii = () => {
  return (
    <div className="servicii-container">
      <div className="cards-services">
        <div className="card card-big">
          <h1>Descoperiți serviciile oferite de experții noștri:</h1>
        </div>
        <div className="card card-small">
          <img
            className="icon-card"
            src={Icon1}
            alt="icoana imagine serviciu proiectare complexa"
          />
          <div className="info-servicii">
            <p className="title-card">PROIECTARE COMPLEXĂ</p>
            <p className="sub-para">
              Servicii complete de proiectare pe toate specialitatile: arhitectură, rezistentă, instalatii si design.
            </p>
          </div>
        </div>
        <div className="card card-small">
          <img
            className="icon-card"
            src={Icon2}
            alt="icoana imagine serviciu design de interior"
          />
          <div className="info-servicii">
            <p className="title-card">DESIGN DE INTERIOR</p>
            <p className="sub-para">
              Creativitate în spații interioare funcționale și estetice.
            </p>
          </div>
        </div>
        <div className="card card-small">
          <img
            className="icon-card"
            src={Icon3}
            alt="icoana imagine serviciu design exterior"
          />
          <div className="info-servicii">
            <p className="title-card">DESIGN DE EXTERIOR</p>
            <p className="sub-para">
              Estetică exterioară captivantă și funcțională.
            </p>
          </div>
        </div>
        <div className="card card-small">
          <img
            className="icon-card"
            src={Icon4}
            alt="icoana imagine serviciu peisagistica"
          />
          <div className="info-servicii">
            <p className="title-card">PEISAGISTICA</p>
            <p className="sub-para">Armonie naturală în creația peisajului.</p>
          </div>
        </div>
        <div className="card card-small">
          <img
            className="icon-card"
            src={Icon5}
            alt="icoana imagine serviciu planificare si consultanta"
          />
          <div className="info-servicii">
            <p className="title-card">PLANIFICARE SI CONSULTANTA</p>
            <p className="sub-para">
              Soluții personalizate pentru medii viabile.
            </p>
          </div>
        </div>
        <div className="card card-small">
          <img
            className="icon-card"
            src={Icon6}
            alt="icoana imagine serviciu randari 3d"
          />
          <div className="info-servicii">
            <p className="title-card">RANDARI 3D</p>
            <p className="sub-para">
              Proiectare tridimensională cu randări realiste.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Servicii;
